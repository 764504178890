<template>
    <div class="beian">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002015727" target="_blank">
            <img src="@/assets/image/beian.png" style="margin-right: 10px;"/>
            <span>浙公网安备 33011002015727号</span>
        </a>
        <a href="https://beian.miit.gov.cn/" target="_blank">
            <span>浙ICP备2021015418号</span>
        </a>
    </div>
</template>

<script>
export default {
    name: "PageFooter",
    data() {
        return {}
    }
}
</script>

<style scoped>
.beian {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: rgb(0,0,0,0.5);
    color: #FFF;
	font-size: 12px;
}

.beian a {
    color: #FFF;
    text-decoration: none;
    display: inline-block;
    margin: 0 10px;
}

.beian a img {
    vertical-align: sub;
}
</style>