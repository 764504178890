export default {
    lang: "English",
    module: {
        1: "代销品",
        101: "设计点",
        201: "微通道平行流-标准品",
        202: "微通道平行流-自己设计",
        203: "微通道平行流-平台设计",
        301: "微通道蛇形管-标准品",
        302: "微通道蛇形管-自己设计",
        303: "微通道蛇形管-平台设计",
		401: "铜管翅片换热器-标准品",
        402: "铜管翅片换热器-自己设计",
        403: "铜管翅片换热器-平台设计",
		501: "板式换热器-标准品",
        502: "板式换热器-自己设计",
        503: "板式换热器-平台设计",
    },
	// <!-- 状态，0，10，11已支付一笔款，20已确认，30已排产，40物料已到位，50生产制作完成，60质量检验完成, -->
	// <!-- 70准备发货，80待支付二笔款（准备发货），81已支付二笔款（准备发货），90已发货，100已完成，200已取消 -->
	orderStatus: {
	    0: "待用户确认",
	    10: "待支付一笔款",
	    11: "已支付一笔款",
	    20: "已确认",
	    30: "已排产",
	    40: "物料已到位",
	    50: "生产制作完成",
	    60: "质量检验完成",
		70: "准备发货",
		80: "待支付二笔款（准备发货）",
		81: "已支付二笔款（准备发货）",
		90: "已发货",
		100: "已取消",
	},
    headerMenu: {
        customPlatform: ["定制平台", "平行流微通道换热器", "蛇形管微通道换热器", "铜管翅片换热器", "板式换热器","丝管换热器"],
        otherMall: "工品商城",
        orderManage: "订单管理",
        complaint: "投诉建议",
        vip: "会员之家",
        accountInfo: "账户信息",
        customerService: "客服",
        SignInOut: "登录/注册",
        SignBack: "退出登录"

    },
	pay:{
		1:'全款',
		2:'分批'
	},
	need:{
		true:'需要',
		false:'不需要'
	},
	orderDetail:{
		id:'订单id',
	    figureNumber:'图号',
		moneyFirst:'一笔款金额',
		moneyOriginal:'原始金额',
		moneySecond:'二笔款金额',
		payFirstTime:'一笔款支付时间',
		paySecondTime:'一笔款金额',
		payType:'支付类型',
		phone:'联系电话',
		purchasingCost:'采购成本',
		returnTime:'退货时间',
		requireInvoice:'需要开发票',
		address:'收货地址',
		figureNumber:'图号',
        realname:'收货人',
		phone:'收货人电话',
		userId:'收货人ID',
		replacePayUserId:'代付用户ID',
		status:'订单状态',
		userRemark:'用户备注',
		companyName:'开票账户',
		createTime:'开票时间',
		dutyParagraph:'企业税号',
		email:'开票邮箱',
		confirmReceiptTime:'确认收货时间',
		address:'收货地址',
		figureNumber:'图号',
		contactId:'跟单员ID',
		courierCompany:'快递公司',
		contractEnUs:'合同中文',
		contractZhCn:'合同英文',
		deliveryDate:'交期',
		discountAgain:'再次下单优惠',
		engineerId:'工程师',
		freightMoney:'运费金额',
		drewInvoice:'已开发票',
		insure:'是否购买保险',
		insureMoney:'购买保险金额',
		goodsMoney:'商品金额',
		module:'所属模块',

	},
    main: {
        actions: {
            normal: "标品订购",
            custom: "我的定制",
            creative: "专属设计",
        }
    },
    login: {
        title: "登录注册",
        disclaimer: "免责声明",
        coollingAgreement: "《酷灵协议》",
        submit: "提交",
		edittt:"提交",
        emailLabel: "电子邮箱",
        verifyCodeLabel: "验证码",
        pleaseInputEmail: "请输入邮箱地址",
        pleaseInputCorrectEmail: "请输入正确的邮箱地址",
        pleaseInputVerifyCode: "请输入验证码",
        getVerifyCode: "获取验证码"
    },
    register: {
        accountInfo: "账户信息",
        companyNameLabel: "*公司名称",
        companyAddressLabel: "*公司地址",
        industryLabel: "所属行业",
        nameLabel: "*姓名",
        genderLabel: "性别",
        pleaseSelectGender: "请选择性别",
        manLabel: "男",
        womanLabel: "女",
        positionLabel: "职位",
        phoneLabel: "*手机号",
        addressLabel: "*详细地址",
        next: "下一步",
        prev: "上一步",
        mesCompanyEmpty: "请输入公司名称",
        mesStrLength: "长度在 3 到 5 个字符",
        mesCompanyAddress: "请输入公司地址",
        mesName: "请输入姓名",
        mesGender: "请选择性别",
        mesPosition: "请输入职位",
        mesPhone: "请输入手机号",
        messAddress: "请输入地址",
        mesPhoneError: "手机号格式错误",
        sentInvoice: "开票信息",
        Enterprise_Tax: "企业税号",
        acceptEmaill: "*接收邮箱",
        depositBank: "开户银行",
        bankAccount: "银行账号",
        EmaillTag: "邮箱号格式错误",
        editTxt: "编辑",
		
    },
    inviteCode: {
        title: "填写邀请码",
        explain: "写后本注册人和邀请码主人都可得采购额的0.5%的礼品券值奖励；不填写则无",
        over: "完成",
        prev: "上一步",
        inviteCodeLabel: "邀请码（选填）",
        inviteCodeEmpty: "邀请码不能为空",
        inviteCodeNumber: "邀请必须为数字值",
        mesAlertContent: "该邀请码可用于提供给他人注册，如他人注册后进行采购，则该邀请码的主人将得到礼品券奖励",
        mesAlertTitle: "提示",
        mesAlertConfirm: "确定"
    },
    userCenter: {
        indexNav: "首页",
        score: "(当前会员分)",
        code: "生成邀请码>",
        coupon: "(礼品券值)",
        gift: "兑换礼品>",
        goodsInfo: "商品信息",
        val: "值",
        price: "单价(元)",
        number: "数量",
        clickCopy: "点击复制",
    },
    orderCenter: {
        orderDel: "删除订单",
        clickComment: "点击评论",
        orderComment: "订单评论",
        orderCommentDesc: "问题描述",
        qualityScore: "质量评分",
        deliveryScore: "交期评分",
        orderStatus: "订单状态",
        step1: '提交订单',
        step2: '付款成功',
        step3: '商品发货',
        step4: '等待发货',
        step5: '完成',
        orderStepTip1: "尊敬的客户，您的订单商品已经从库房发出，请做好收货准备。",
        orderStepTip2: "如果您已收到货物，请点击确认收货按钮完成订单",
        orderSn: "订单号：",
        consigneeInfo: "收货人信息",
        consignee: "收货人",
        consigneeAddress: "收货人地址",
        consigneePhone: "收货人手机",
        payInfo: "付款信息",
        payMethod: "付款方式",
        payTime: "付款时间",
        deliverInfo: "配送信息",
        deliverMethod: "配送方式",
        deliverTime: "交货时间",
        packNumber: "包装数量",
        goodsName: "商品名称",
        goodsSpec: "商品规格",
        goodsType: "商品类型",
        goodsPrice: "单价（元）",
        goodsNumber: "数量",
        remindDelivery: "提醒酷灵猫发货",
        goodsTotal: "商品总额",
        packTotal: "包装总额",
        freightTotal: "运费总额",
        total: "总计",
		kefu:'客服',
		complain:"投诉"
		
    },
    exchangeGift: {
        giftName: "礼品名称",
        giftType: "礼品型号",
        defaultAddress: "默认地址",
        mesGiftName: "礼品名称不能为空",
        mesGiftType: "礼品型号不能为空",
        mesDefaultAddress: "默认地址不能为空",
    },
    userService: {
        phoneText: "可拨打电话，联系平台主管或平台管理人员",
        aboutUs: "关于我们",
        field: "应用领域"
    },
    applyInvoice: {
        applyInvoice: "申请开发票",
        gongpinMall: "工品商城",
		biaozhuanMall:'标准品订购',
        orderTime: "下单时间",
        type: "类型",
        designRecharge: "设计点充值",
        getOrderDetail: "点击查看订单详情",
        subtotal: "小计",
        editContent: "修改内容",
        dutyParagraph: "企业税号",
        depositBank: "开户银行",
        bankAccount: "银行账号",
        phone: "手机号",
        email: "接收邮箱",
        confirmEdit: "确认修改",
        mesdutyParagraphEmpty: "企业税号未填写",
        mesDepositBankEmpty: "开户银行未填写",
        mesBankAccountEmpty: "银行账号未填写",
        mesPhoneEmpty: "手机号未填写",
        mesEmailEmpty: "接受邮箱未填写",
    },
    message: {
        message: '消息通知',
        notice: "公告",
        creativeWorkshop: "创意工坊",
        reply: "回复",
        platformNotice: "平台公告"
    },
    customDesign: {
        designNumber: "当前剩余设计点",
        designTip: "每购买500元货品，赠送1个设计点数",
        clickRecharge: "点击充值",
        rechargeAmount: "充值金额",
        cardNumber: "银行卡号",
        cardPwd: "卡号密码",
        mesRechargeAmount: "请输入金额",
        mesAmountNumber: "金额为数字",
        mesCardNumber: "请输入卡号",
        mesCardPwd: "请输入密码",
        confirmPay: "确认支付",
    },
    capital: {
        buyTotal: "采购总额",
        refundTotal: "退款总额"
    },
    navTop: {
        index: "首页",
        userhome: "会员之家",
        usercenter: "会员中心",
        userservice: "会员服务",
        applyinvoice: "申请开发票",
        editinvoice: "修改内容",
        customdesign: "自定义设计点",
        designrecharge: "自定义设计点充值",
        capital: "资金明细",
        gifts: "兑换礼品",
        ordercenter: "订单中心",
        comment: "订单评论",
        detail: "订单详情",
        account: "账户信息",
        complain: "投诉建议",
        quality: "质量投诉",
        delivery: "交期投诉",
        service: "服务投诉",
        orderadmin: "订单管理",
		complainIndex:"投诉"
    },
    operation: {
        select: "选择",
        del: "删除",
        confirm: "确认",
        confirmSubmit: "确认提交",
        pleaseSelect: "请选择",
        search: "搜索",
        orderNow: "立即订购",
        orderSmaple: "订购样品",
    },
    complain: {
        complainQuality: "质量投诉",
        complainDelivery: "交期投诉",
        complainService: "服务投诉",
        goodsOrderSn: "商品订单号",
        complainType: "投诉类型",
        desc: "问题描述",
        mesGOS: "请填写商品订单号",
        mesComplainType: "请选择投诉类型",
        mesDesc: "请填写问题描述",
        phone: "联系电话",
        mesPhone: "请输入联系电话",
        aboutImage: "相关图片",
        rateval: "评分"
    },
    orderAdmin: {
        clickPay: "点击支付",
        refundOrder: "退回订单",
        confirmReceive: "确认收货",
		  elsePay: "他人代付",
    },
    mall: {
        zongHe: "综合",
        typeNumber: "型号",
        price: "价格",
        size: "尺寸筛选",
        standardOrdering: "标准品订购",
        allCategory: "所有类目",
        mesPleaseEnterModel: "请输入型号",
		mesPeakprice: "最高价格",
		mesBottomprice: "最低价格",

        mesChooseAccordingWorkingCondition: "根据性能筛选",
        salesVolume: "销量",
        length: "长度",
        height: "高度",
        customDesign: "自己设计",
        workShop: "创意工坊",
        mesHighToLow: "价格由高到低",
        mesLowToHigh: "价格由低到高",
        sold: "已售",
        piece: "件",
        MinimumOrderQuantity: "起订量",
        sales: "销量",
        salesToLow: "销量从高到底",
        salesToHigh: "销量从低到高",
        paixu: "排序"

    },
    goodsDetail: {
        goodsPrice: "批量价格",
        smaplePrice: "样品价格",
        smaplePriceValue: ["批量订购", "样品订购"],
        boxSize: "包装规格",
        boxSizeValue: ["小箱", "标准箱"],
        supplyModes: "订购方式",
        sale: "已售",
        standardNember: "型号",
        TransactionTime: "交期",
        goodsModel: "型号",
        num: "采购量",
        workInfo: "工况",
        packNum: "包装规格",
        goodsInfo: "商品信息",
        FreightInsurance: "运费险",
        TransactionSupport: "交易",
        InsurancePayment: "交易支持",
        Payment: "支付方式",
        PaymentMethod: "微信，支付宝，银行卡",
        IndustrialMaterials: "工业材料",
        WorkingConditionSelection: "工况选择",
        ProcessType: "工业类型",
        WorkpieceType: "工况类型",
        ProcessingCycle: "加工周期",
        ProcessingTrade: "加工贸易形式",
        goodsType: "产品类型"
    },
    goodsPay: {
        size: "规格",
        goodsInfo: "商品信息",
        goodsType: "类型",
        goodsPrice: "单价",
        goodsNum: "数量",
        goodsSubtotal: "小计 ",
        BrandName: "品牌名称"
    },
    unit: {
        day: "天",
        piece: '件',
    },

    common: {
        null: "无",
        boolean: {
            true: "是",
            false: "否",
        },
        actions: {
            next: "下一步",
            pre: "上一步",
            back: "返回",
            confirm: "确定",
            cancel: "取消",
            print: "打印",
            done: "完成",
            choose: "选择",
        },
    },
    tips: {
        notEmpty: "不能为空",
    },

    custom: {
        material: {
            "铜": "铜",
            "铝": "铝",
        },
        shap: {
            0: "直管",
            1: "弯管",
        },
        bracketPosition: {
            0: "水平安装",
            1: "垂直安装",
        },
        hoodPosition: {
            0: '风罩底部安装',
            1: '风罩两侧安装',
        },

        page0: {
            "工况": "工况",
            "工况类型": "工况类型",
            "冷媒": "冷媒",
            "排气温度": "排气温度",
            "排气压力": "排气压力",
            "过冷度": "过冷度",
            "环境温度": "环境温度",
            "风速": "风速",
            "风机数量": "风机数量",
            "输入风机曲线": "输入风机曲线",
            "无风机": "风机未知",
            "选择风机": "选择风机",
            "自定义风机曲线": "自定义风机曲线",
            "风量": "风量",
            "风压": "风压",

            "风机数量范围": "风机数量的有效范围：1 ~ 6"
        },

        page1: {
            "芯体": "芯体",
            "长": "长",
            "高": "高",
            "排数": "排数",
            "扁管": "扁管",
            "宽": "宽",
            "孔数": "孔数",
            "产线": "产线",
            "FPI": "FPI",
            "翅片": "翅片",
            "计算性能": "计算性能",
			"爆破压力": "爆破压力",
        },

        page2: {
            "性能": "性能",
            "流量": "流量",
            "冷媒降压": "冷媒降压",
            "换热量": "换热量",
            "出风温度": "出风温度",
            "冷媒充注量": "冷媒充注量",
            "流程": "流程",
            "扁管数量": "扁管数量",
            "扁管型号": "扁管型号",
            "翅片型号": "翅片型号",
        },

        page3: {
            "部件": "部件",
            "部件类型": "部件类型",
            "风罩": "风罩",
			"无": "无",
            "支架": "支架",
            "风扇直径": "风扇直径",
            "安装风机": "是否预留风机固定孔",
            "风机数量": "风机数量",
            "风机安装孔直径": "风机安装孔距",
            "拉铆螺母规格": "拉铆螺母规格",
            "风罩位置": "风罩安装位置",
            "是否翻边": "是否翻边",
            "支架位置": "安装面角度",
            "支架类型": "支架规格",
            "厚": "厚",
            "孔管距": "孔管距",

            "风扇直径小于等于": "风扇直径的有效范围： <= {0}"
        },

        page4: {
            "接管参数": "接管参数",
            "材质": "材质",
            "形状": "接管形状",
            "位置": "接管位置",
            "进口管": "进口管规格",
            "出口管": "出口管规格",
            "管径": "管径",
            "壁厚": "壁厚",
            "扩口": "扩口",
            "是否扩口": "是否扩口",
            "扩口大小": "扩口直径",
        },

        page5: {
            "其他": "其他",
            "涂层": "涂层",
            "涂层0": "无",
            "涂层1": "钝化",
            "涂层2": "电泳",
			"涂层3": "电泳&喷粉",
            "是否充氮": "是否充氮",
        },

        page6: {
            print: {
                title: "方案报告",
                priductModel: "产品型号",
            },
            "生成设计图": "生成设计图",
            "支架规格": "支架规格",
        },

    },

	newadd: {
		t: {
			"首页": "首页",
			"用户": "用户",
			"酷灵猫": "酷灵猫",
			"邮编": "邮编",
			"当前会员积分": "当前会员积分",
			"生成二维码": "生成邀请码",
			"礼品券值": "礼品券值",
			"兑换礼品": "兑换礼品",
			"会员中心": "会员中心",
			"自定义设计点": "定制设计点",
			"当前剩余设计点": "当前剩余设计点",
			"每购买500元货品，赠送1个设计点数": "每购买500元货品，赠送1个设计点数",
			"点击充值": "点击充值",
			"商品信息": "商品信息",
			"值": "值",
			"单元": "单元",
			"数量": "数量",
			"请选择货币方式": "请选择货币方式",
			"请输入邮编": "请输入邮编",
			"地址": "地址",
			"详细地址": "详细地址",
			"保险金额": "保险金额",
			"运费": "运费",
			"商品总金额": "商品总金额",
			"一笔款金额": "一笔款金额",
			"原始金额": "原始金额",
			"二笔款金额": "二笔款金额",
			"应付总金额": "应付总金额",
			"提交订单": "提交订单",
			"提交订单": "提交订单",
			"默认地址": "默认地址",
			"保险赔付": "保险赔付",
			"运险费": "运险费",
			"我要开票": "我要开票",
			"订单备注": "订单备注",
			"全款支付": "全款支付",
			"分期支付": "分期支付",
			"申请开票": "申请开票",
			"设计点充值": "设计点充值",
			"确认充值": "确认充值",
			"我要修改": "我要修改",
			"姓名": "姓名",
			"电话": "电话",
			"取消": "取消",
			"确认": "确认",
			"选择": "选择",
			"单位预设":"单位预设",
			"价格排序":"价格排序",
			"品牌筛选":"品牌筛选",
			'尺寸筛选':'尺寸筛选',
			'长度':'长度',
			"宽度":"宽度",
			"根据工况选型":"根据性能选型",					
			"性能检测":"计算性能",
			"开始检测":"计算性能",		
			"所属行业(选填)":"所属行业(选填)",
			"请填写所属行业(选填)":"请填写所属行业(选填)",
			'规格':'规格',
			"礼物名称":'礼物名称',
			"礼品型号":'礼品型号',
			"状态":'状态',
			"金额":'金额',
			"设计点":'设计点',
			"时间":'时间',
			"温度":'温度',
			"体积流量":'体积流量',
			"压力":'压力',
			"质量流量":'质量流量',
			"速度":'速度',
			"质量":'质量',
			"充注量":'充注量',
			'清空':'清空',
			"选择工况":"选择工况",
			"最大长度不能超过":'最大长度不能超过',
			"最大高度不能超过":'最大高度不能超过',
			"格式不正确":'格式不正确',
			"请填写数据":"请填写数据",
			"内容积":"内容积",
			'空气测压降':'空气测压降',
			'冷媒质量流量':"冷媒质量流量",
			"登陆成功":"登陆成功",
			"输入代付用户":'输入代付用户',
			"选择支付方式":'选择支付方式',
			"微信支付":'微信支付',
			"支付宝支付":'支付宝支付',
			"对公账户":'对公账户',
			"中国银联":'中国银联',
			"客服正在为您服务":'客服正在为您服务',
			"工程师正在为您服务":' 工程师正在为您服务',
			"酷灵猫客服很高兴为您服务":'酷灵猫客服很高兴为您服务',
			"请输入内容":"请输入内容",
			"发送图片":"发送图片",
			"发送":"发送",
			"暂无会话消息":"暂无会话消息",
			"功能开发中":'功能开发中',
			"该手机号已经被注册":"该手机号已经被注册",
			"该邮箱已注册":'该邮箱已注册'
		}
	}
}