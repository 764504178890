import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './plugins/lang'
import './plugins/JsPlus'
import './plugins/BigDecimal'
import i18n from "@/plugins/i18n";


import Print from "@/plugins/Print";
import '@/assets/css/global.css';


import req from "@/api/Request"
Vue.prototype.$req = req

Vue.use(Print)

Vue.filter('toFix', function(value) {
	if (!value) return '0.00';
	value = Number(value);
	return value.toFixed(2)
})


// 工具类
import  '@/utils/common.js';



import VueSocket from "@/api/common/socket/vue-socket";
Vue.use(VueSocket, {
  baseUrl:req.getServerPath(),
  url: "/connect-socket",
})




Vue.config.productionTip = false



let vueThis= new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')

export default vueThis