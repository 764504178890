import fetch from "@/api/fetch";
import store from "@/store";

export default {
  post(url, data, params) {
    return fetch({url, method: 'POST', params, data})
  },
  get(url, params) {
    return fetch({url, method: 'GET', params})
  },
  put(url, data, params) {
    return fetch({url, method: 'PUT', params, data})
  },
  patch(url, data, params) {
    return fetch({url, method: 'PATCH', params, data})
  },
  delete(url, params) {
    return fetch({url, method: 'DELETE', params})
  },
  getFileUrl(...attr) {
    function get(url) {
      if (url) {
        if (url.startsWith('http://') || url.startsWith('https://')) return url;
        return store.getters.fileServer + url;
      }
    }

		//遍历传入的所有参数，找到第一个有效的参数并返回
		for (let i in attr) {
		  let url = get(attr[i]);
		  if (url) return url;
		}
		return '';
	  },
	  
	  
	 getServerPath() {
		return "https://www.hxcoolingmall.com/jeecg-boot";
	  },

}